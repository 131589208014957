import { useCallback, useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ImgLogo from "@Channel/images/logo.png";
import ImgItslays from "@Channel/images/itslays.png";
import ImgDownload from "@Channel/images/download.png";
import "./index.scss";
//@ts-ignore
import Swiper from 'swiper/bundle';
import 'swiper/css';
import SafeArea from '@/components/SafeArea';
import Home from './components/Home';
import MNav from './components/MNav';
import Game from './components/Game';
import PersonalHonor from './components/PersonalHonor';
import { Toaster, toast } from 'sonner';
import { useDownload, useRefState } from "@/hooks";
import { getUuid, isIOS } from "@/utils/tool";
import { getApkDownloadLink, log, OperationType } from "@/services/home";

function HomePage() {
  const [selectNav, setSelectNav] = useState(1)//轮播图切换1-5，默认1
  const refSelectNav = useRef(selectNav)
  refSelectNav.current = selectNav

  const refSwiper = useRef<any>(null);

  const [, setLink, refLink] = useRefState({ android: '', ios: '' })

  const { download } = useDownload()

  const handleDownload = useCallback(() => {
    log(getUuid(), OperationType.Click)

    const link = isIOS ? refLink.current.ios : refLink.current.android
    download(link)
  }, [])

  const fetchApkDownloadLink = useCallback(async () => {
    const res = await getApkDownloadLink()
    if (!res) return
    const { data, message, code } = res
    if (code !== 200) {
      toast(message)
      return
    }
    if (!data) return

    setLink({
      android: res.data.officialAndApk,
      ios: res.data.officialIosApk
    })
  }, [])

  /* 初始化 */
  useEffect(() => {
    // 初始化swiper
    refSwiper.current = new Swiper('.swiper', {
      // allowTouchMove: false,//禁止鼠标滑动切换
      direction: 'vertical',//垂直切换
      speed: 500,//切换速度500
      mousewheel: true,//开启鼠标滚轮控制Swiper切换
      threshold: 30, //如果触摸距离小于该值滑块不会被拖动
      on: {
        slideChangeTransitionStart: function () {
          //@ts-ignore
          setSelectNav(this.activeIndex + 1)
        },
      },
    });

    fetchApkDownloadLink()

    return () => {
      refSwiper.current?.destroy()
    }
  }, []);

  // js控制swiper切换
  useEffect(() => {
    if (refSwiper.current) {
      refSwiper.current.slideTo(selectNav - 1);
    }
  }, [selectNav])

  useEffect(() => {
    log(getUuid(), OperationType.PageView)
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <title>itslays</title>
        <meta name="viewport" content="width=device-width, viewport-fit=cover, initial-scale=1.0, user-scalable=no" />
      </Helmet>
      <div className="pg-channel-home">
        <header>
          <SafeArea position="top"></SafeArea>
          <div className="content">
            <div className="left">
              <img src={ImgLogo} alt=""></img>
              <img src={ImgItslays} alt=""></img>
            </div>
            <div className="right">
              <button className="top-up" onClick={handleDownload} />
            </div>
          </div>
        </header>
        <nav>
          <MNav selectNav={selectNav} setSelectNav={setSelectNav} />
        </nav>
        <div className="swiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide"><Home /></div>
            <div className="swiper-slide"><Game /></div>
            <div className="swiper-slide"><PersonalHonor /></div>
          </div>
        </div>
        <Toaster position="top-center" richColors expand={true} />
      </div>
    </HelmetProvider>
  );
}

export default HomePage;
